

export default [
  {
    path: "/",
    component: () => import("../views/Home.vue"),
    name: "Home",
    meta: {name: "Home", title: "홈", headerDark: 'none'},
  },
  {
    path: "/contact",
    component: () => import("../views/contact/Contact.vue"),
    name: "contact",
    meta: {name: "contact", title: "찾아오는길", headerDark: 'none'},
  },
  {
    path: "/solution/sensor",
    component: () => import("../views/solution/Sensor.vue"),
    name: "Sensor",
    meta: {name: "Sensor", title: "아쿠아센서",headerDark:'dark',},
  },
  {
    path: "/solution/feed",
    component: () => import("../views/solution/Feed.vue"),
    name: "Feed",
    meta: {name: "Feed", title: "아쿠아급이",headerDark:'dark',},
  },
  {
    path: "/solution/erp",
    component: () => import("../views/solution/ERP.vue"),
    name: "ERP",
    meta: {name: "ERP", title: "아쿠아ERP", headerDark:'dark',},
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/",
  }
];
