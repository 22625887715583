import { createStore } from 'vuex'
import userInfo from './user/UserInfo'

// Vue.use(Vuex)

const state = {
  PagePathStatus: true,
  PageParentBackPath: () => {
  },
  PageChildBackPath: () => {
  },
  partner: {},
  backStatus: {
    registerBack: true
  }
};

export default createStore({
  state,
  modules: {
    userInfo,
  },
  plugins: [

    // createPersistedState(),

  ],
})
