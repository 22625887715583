<template>
    <div style="display: contents;">
        <page-header :isDark = returnRouter()></page-header>
        <router-view/>
    </div>

</template>

<script>
import alertMixins from "@/mixins/alertMixins";
import AuthMixins from "@/mixins/AuthMixins";
import EventBus from "@/utils/event-bus";
import PageHeader from "@/components/PageHeader.vue"
import MobileDetect from "mobile-detect";
export default {
    name: "App",
    mixins: [alertMixins, AuthMixins],
    components: {
        PageHeader
    },
    provide() {
        return {
        }
    },
    props: {},
    data() {
        return {
        }
    },
    created() {
        let md = new MobileDetect(window.navigator.userAgent);
        if (md.mobile() === null) {
            location.href = "http://aquanuri.io";
        }

    },
    mounted() {
    },
    beforeUnmount() {
    },
    unmounted() {
    },
    computed: {},
    methods: {

        returnRouter(){
            if( 
                this.$route.path === '/solution/sensor'
                || this.$route.path === '/solution/feed'
                ||  this.$route.path === '/solution/erp'

            ){
                return true
            }
        },





    },
    watch: {},
}
</script>

<style lang="scss">
@import "./assets/scss/index.scss";

</style>
