
import {mapState} from "vuex";
import util from "@/mixins/util";
import {logout} from "@/mixins/Auth";
import Swal from 'sweetalert2'
export default {
    computed: {
        ...mapState({
            UserInfo: state => state.userInfo.UserInfo,
        })
    },
    methods: {
        checkTimeLimit(){
            let timeLimit = this.$localStorage.get('timeLimit');
            if(timeLimit){
                let nowTime = this.$date.add("-10", "m").format('YYYY-MM-DD HH:mm:ss');
                if(timeLimit < nowTime){
                    this.$localStorage.set('timeLimit',this.$date().format('YYYY-MM-DD HH:mm:ss'))
                    Swal.fire({
                        title: '',
                        html: '10분 초과하였습니다. 재로그인 해주세요.',
                        confirmButtonText: '확인',
                        customClass: 'nhAlert_main',
                        onClose: () => {
                            return logout();
                        }
                    })
                }else{
                    this.$localStorage.set('timeLimit',this.$date().format('YYYY-MM-DD HH:mm:ss'))
                    return true;
                }
            }else{
                return false;
            }

        },
        checkAdmin(no){
            if(!util.isEmpty(this.UserInfo)){
                return this.UserInfo.mb_id === 'admin' || this.UserInfo.mb_no == no
            }else{
                return false
            }

        },
        checkDeleteAdmin(no){
            if(!util.isEmpty(this.UserInfo)){
                return (this.UserInfo.mb_id === 'admin' || this.UserInfo.mb_no == no) && (this.UserInfo.mb_no != no &&  this.UserInfo.mb_id === 'admin')
            }else{
                return false
            }

        },
        checkSuperAdmin(){
            if(!util.isEmpty(this.UserInfo)){
                return this.UserInfo.mb_id === 'admin'
            }else{
                return false
            }
        }
    },
}
