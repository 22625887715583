<template>
    <div class="container-header"  :class="[{'active' : this.Active},{'reverse' : this.Reverse}]">
        <div class="wrap-header" :class="[{'active' : this.Active && !isDark},{'reverse' : this.Reverse && !isDark},{'dark' : isDark}]">
            <div class="logo-header" @click="routerPush()"></div>
            <div class="title-header" @click="routerPush()">AQUANURI</div>
            <div class="hamberger-header" @click="headerAnimation()">
                <div class="box-line-hamberger">
                    <div class="hamberger-line first"></div>
                    <div class="hamberger-line second"></div>
                    <div class="hamberger-line third"></div>
                </div>
            </div>
        </div>
        <div class="wrap-header-actice" :class="[{'active' : this.Active},{'reverse' : this.Reverse}]">
            <div class="title-actice-header"  @click="routerPush('solution/sensor')">Solution</div>
            <div class="subtitle-actice-header" @click="routerPush('solution/sensor')">아쿠아센서</div>
            <div class="subtitle-actice-header" @click="routerPush('solution/feed')">아쿠아급이</div>
            <div class="subtitle-actice-header mb50" @click="routerPush('solution/erp')">아쿠아ERP</div>
            <div class="title-actice-header" @click="routerPush('contact')">Contact</div>
        </div>
    </div>
</template>


<script>
export default {
    name: "PageHeader",
    mixins: [],
    components: { },
    props: {
        isDark:{
            default : () => false
        }

    },
    data() {
        return {
            Active: false,
            Reverse: false,
        }
    },
    beforeRouterEnter() {},
    created() {},
    mounted() {},
    beforeUnmount() {},
    unmounted() {},
    computed: {},
    methods: {
        headerAnimation() {
            this.Active = !this.Active;
            if (!this.Active) { // 첫 로딩시 애니메이션 효과를 막기 위한 처리
                this.Reverse = true;
            } else {
                this.Reverse = false;
            }
        },
        routerPush(data,header) {
            this.$router.push(`/${data}`);
            if(this.Active) {
                this.headerAnimation();
            } else {
                this.Reverse = false;
            }            
        },
        headerCheck(){
        }
    },
    watch: {
    },
}
</script>
